
//https://codepen.io/edward1995/pen/MOKdXO?editors=1010


import api from "@/api/api";
import homeEvents from "@/api/home/index";
import Vue from "vue";
import LazyHydrate from "vue-lazy-hydration";
//import socket from '~/plugins/socket.io.js'
import configVars from "@/config/config_vars";
let appFrontUrl = configVars.appFrontUrl;
let PUSH_NOTIFICATION_ENABLE = configVars.PUSH_NOTIFICATION_ENABLE;
import eventsApi from "@/api/events";
import microformatos from "@/api/microformatos";
import { mapGetters } from "vuex";
import Cookie from "js-cookie";
import categoriasMenu from "@/api/categorias/categorias";
import scroll from "@/util/scroll"
//import facebook from '@/api/facebook'
let components = {
  LazyHydrate,
};
import windowScrollPosition from '@/mixins/window-scroll-position'
//https://github.com/nuxt/nuxt.js/issues/978 <<--ejemplo de 2 requests en paralelo
export default {
  mixins: [windowScrollPosition('position')],
  speedkitComponents: {

    SpeedUserArtistSelectModal: () =>
      import("@/components/User/ArtistSelectModal"),
  },
  components: components,
  middleware: ["check-sender", "check-auth"],
  async asyncData(context) {
    let ip = "";
    let visto = true;
    let numVistas = 0;
    let numVistasHwi = 0;
    let numVistasMovil = 0;
    if (context.req) {
      ip = context.req.headers["x-forwarded-for"];
      if (ip) {
        var list = ip.split(",");
        ip = list[list.length - 1];
      } else {
        ip = context.req.connection.remoteAddress;
      }

      context.store.commit("storeIP", ip);

      if (context.req.headers.cookie != undefined) {
        let stringCookies = context.req.headers.cookie.split(";");

        if (stringCookies != undefined) {
          let stringNumVistas = stringCookies.find((c) =>
            c.trim().startsWith("numVistas=")
          );

          if (stringNumVistas != undefined) {
            let valueNumVistas = stringNumVistas.split("=")[1];

            if (valueNumVistas != undefined) {
              if (!isNaN(valueNumVistas)) {
                numVistas = Number(valueNumVistas) + 1;
              } else {
              }
            }
          }

          let stringNumVistasHwi = stringCookies.find((c) =>
            c.trim().startsWith("numVistasHwi=")
          );

          if (stringNumVistasHwi != undefined) {
            let valueNumVistas = stringNumVistasHwi.split("=")[1];

            if (valueNumVistas != undefined) {
              if (!isNaN(valueNumVistas)) {
                numVistasHwi = Number(valueNumVistas) + 1;
              } else {
              }
            }
          }
        }
      }

      context.store.commit("storeNumVistas", numVistas);
      context.store.commit("storeNumVistasHwi", numVistasHwi);
    } else if (process.browser) {
      /**
       * ==========================
       *  store num vistas
       * ==========================
       */
      context.store.commit(
        "storeNumVistas",
        context.store.getters.numVistas + 1
      );

      numVistas = context.store.getters.numVistas + 1;
      var inFifteenMinutes = new Date(new Date().getTime() + 1 * 100);

      Cookie.set("numVistas", context.store.getters.numVistas, {
        expires: inFifteenMinutes,
      });

      /**
       * ==========================
       *  store num hwi
       * ==========================
       */
      context.store.commit(
        "storeNumVistasHwi",
        context.store.getters.numVistas + 1
      );

      numVistasHwi = context.store.getters.numVistasHwi + 1;
      var inFifteenMinutes = new Date(
        new Date().getTime() + 1 * 1000 * 60 * 60 * 24 * 365
      );

      Cookie.set("numVistasHwi", context.store.getters.numVistasHwi, {
        expires: inFifteenMinutes,
      });

      ip = context.store.getters.IP;
      if (ip != "") {
        localStorage.setItem("ip", ip);
      }
      if (localStorage.getItem("ip") != "") {
        ip = localStorage.getItem("ip");
      }
    }

    /*ip = "136.0.16.217"
    context.store.commit("storeIP", ip);  */

    if (numVistas > 0) {
      visto = true;
    } else {
      visto = false;
    }

    let toogleIntroSplashHwi = true;
    if (numVistasHwi > 0) {
      toogleIntroSplashHwi = false;
    }

    if (context.query.v != undefined) {
      if (context.query.v == "true") {
        toogleIntroSplashHwi = false;
      }
    }
    let lat
    let lon
    try {
      let info = await api.getLocationInfo(ip)

      lat = info.data.latitude
      lon = info.data.longitude
      context.store.commit("storeCoordinates", { lat: lat, lon: lon });


    } catch (error) {
      console.error('error ' + error)
      lat = 0
      lon = 0
    }
    //visto = true;

    let tevo5NextDaysCarrusel = {
      eventos: [],
      query: {}
    }

    let per_page = 10

    try {

      tevo5NextDaysCarrusel = await homeEvents.getEvents(lat, lon, 1, per_page)
      context.store.commit("eventcards/homeCards", tevo5NextDaysCarrusel.eventos);
      context.store.commit("eventcards/locality", tevo5NextDaysCarrusel.locality);
      context.store.commit("eventcards/queryBase", tevo5NextDaysCarrusel.query);

    } catch (error) {
      console.log(`error ${error}`)
    }




    return {
      numVistasHwi,
      concertsMenu: categoriasMenu.concerts,
      sportsMenu: categoriasMenu.sports,
      specialEventsMenu: categoriasMenu.special_events,
      theatreMenu: categoriasMenu.theatre,
      pageImage: `${appFrontUrl}img/pepper_tix_share_events.jpg`,
      isCreated: false,
      visto: visto,
      toogleIntroSplashHwi: toogleIntroSplashHwi,
      //queryBase1 : tevo5NextDaysCarrusel.query,
    };
  },
  async fetch(context) {
    //await store.dispatch("performers/searchPerformerByQuery", parametros);
    //await store.dispatch("performers/storePerformerByName", params.search);

  },
  head() {
    //https://github.com/nuxt/nuxt.js/issues/2536 <--Mirar aqui

    const parentList = [
      {
        url: `https://peppertix.org/concerts`,
        text: `Concerts`,
      },
      {
        url: `https://peppertix.org/sports`,
        text: `Sports`,
      },
      {
        url: `https://peppertix.org/special-events`,
        text: `Special Events`,
      },
      {
        url: `https://peppertix.org/theatre`,
        text: `Theatre`,
      },
    ];

    const concertsList = this.concertsMenu.map((item) => {
      return {
        url: `https://peppertix.org/concerts/${item.slug}`,
        text: item.name,
      };
    });

    const sportsMenuList = this.sportsMenu.map((item) => {
      return {
        url: `https://peppertix.org/sports/${item.slug}`,
        text: item.name,
      };
    });

    const specialEventsMenuList = this.specialEventsMenu.map((item) => {
      return {
        url: `https://peppertix.org/special-events/${item.slug}`,
        text: item.name,
      };
    });

    const theatreMenuList = this.theatreMenu.map((item) => {
      return {
        url: `https://peppertix.org/theatre/${item.slug}`,
        text: item.name,
      };
    });

    let concertsBreadcrumbList = microformatos.breadcrumbList(concertsList);
    let sportsBreadcrumbList = microformatos.breadcrumbList(sportsMenuList);
    let specialEventsBreadcrumbList = microformatos.breadcrumbList(
      specialEventsMenuList
    );
    let theatreBreadcrumbList = microformatos.breadcrumbList(theatreMenuList);
    let parentBreadCrumbList = microformatos.breadcrumbList(parentList);

    let structuredData = microformatos.getEventsStructuredData(this.homeCards);

    return {
      title: `Pepper Tix Events`,
      meta: [
        {
          hid: "description",
          name: "description",
          content: `Your home for events tickets! Discover the best deals on tickets for concerts, sports, theater, and special events at Pepper Tix. Find your next unforgettable experience now!`,
        },
        {
          hid: "og:description",
          name: "og:description",
          content: `Be the first to know about your favorite artist and upcoming events! Your go-to destination for tickets to concerts, sports, theater, and special events. Find the hottest tickets at Pepper Tix`,
        },
        {
          hid: "keywords",
          name: "keywords",
          content: "search tickets,search concerts, sports, theater, events, live events, music, shows,search discount tickets, find cheap tickets, festival tickets, search events near me,",
        },
        {
          property: "og:site_name",
          content: "Pepper Tix",
        },
        {
          property: "og:url",
          content: `${appFrontUrl}`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: "Discover Top Event Tickets at Pepper Tix - Your Event Ticket Marketplace",
        },
        {
          property: "og:image",
          content: `${appFrontUrl}img/pepper_tix_share_events.jpg`,
        },
      ],
      __dangerouslyDisableSanitizers: ["script"],
      script: [
        {
          innerHTML: JSON.stringify(microformatos.organization()),
          type: "application/ld+json",
        },
        {
          innerHTML: JSON.stringify(microformatos.search()),
          type: "application/ld+json",
        },
        {
          innerHTML: JSON.stringify(parentBreadCrumbList),
          type: "application/ld+json",
        },

        {
          innerHTML: JSON.stringify(concertsBreadcrumbList),
          type: "application/ld+json",
        },
        {
          innerHTML: JSON.stringify(sportsBreadcrumbList),
          type: "application/ld+json",
        },
        {
          innerHTML: JSON.stringify(specialEventsBreadcrumbList),
          type: "application/ld+json",
        },
        {
          innerHTML: JSON.stringify(theatreBreadcrumbList),
          type: "application/ld+json",
        },

        {
          innerHTML: JSON.stringify(structuredData),
          type: "application/ld+json",
        },
        {
          innerHTML: JSON.stringify(microformatos.cityList()),
          type: "application/ld+json",
        },

      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isAutenticated: "user/isAutenticated",
      prospect: "user/prospect",
      media_match: "media_match",
      openTrackArtist: "openTrackArtist",
      homeCards: "eventcards/homeCards",
      locality: "eventcards/locality",
      queryBase: "eventcards/queryBase",
      userIsVIPMember: "user/userIsVIPMember",
      vipSplashVisto: "vipSplashVisto",
      openCategoriesMenu: "openCategoriesMenu",
      openMobileSearch: "openMobileSearch",
      isClickBody: "isClickBody",
      openCategoryMenu: "openCategoryMenu",
      magic_flag: "magic_flag",
      toogleSignIn: "toogleSignIn",

      activePromoCode: "checkout/activePromoCode",
      isActivePromoBeenSetUp: "checkout/isActivePromoBeenSetUp",
      tooglePromoRegister: "tooglePromoRegister",
      userHasPromoCode: "user/userHasPromoCode",
      promoCodeForApply: "user/promoCodeForApply",
      showPromoToast: "showPromoToast"

    }),
  },
  name: "app",
  components: components,
  data() {
    return {
      showInner: true,
      showConcertsMenu: false,
      showSportsMenu: false,
      showMoreMenu: false,
      finalCurrentScrollPosition: 0,
      structuredData: [],
      show_more: true,
      tryForGetMoreEvents: true,
      attrs: {
        controls: false,
        playsinline: true,
        loop: true,
        autoplay: true,
        muted: true,
      },
      showInfoNews: true,
      PUSH_NOTIFICATION_ENABLE: PUSH_NOTIFICATION_ENABLE,
      privacy_policy: "",
      dialogError: false,

      showHWi: false,
      visto: true,
      isMounted: false,
      description: "Your home events tickets",
      appFrontUrl: appFrontUrl,
      invitation_code: this.user ? `?invitation_code=${this.user._id}` : "",
      prueba: "Home",
      morePopularPerformers: [],
      cargando: true,
      showArtists: true,
      artists: [],
      transform: "",
      scroll_on_track_artist: false,
      //myArtists: [],
      isFavorite: true,

      administrative_area_level_1: "",
      per_page: 12,
      page: 1,

      isModalVisible: false,
      position: {
        latitude: null,
        longitude: null,
      },
      carrusel_per_page: 3,
      access_token: null,

      toogleSignUp: false,

      toogleNotification: true,
      toogleIntroSplash: false,
      tevo5NextDaysCarrusel: {
        per_page: 4,
        eventos: [],
      },
      tmByLocationCarrusel: {
        per_page: 4,
        eventos: [],
      },
      chicago: {
        lat: 41.85003, //chicago
        lon: -87.65005, //chicago
      },
      vegas: {
        lat: 36.17497, //las vegas
        lon: -115.1372, //las vegas
      },
      sanfrancisco: {
        lat: 37.77493,
        lon: -122.41942,
      },
      bogota: {
        lat: 4.624335,
        lon: -74.063644,
      },
      nashville_TN: {
        lat: 36.174465,
        lon: -86.76796,
      },
      newHeaven_CT: {
        lat: 41.308273,
        lon: -72.927887,
      },
      buffalo_NY: {
        lat: 42.963947,
        lon: -78.737808,
      },
      albany_NY: {
        lat: 42.65258,
        lon: -73.756233,
      },
      austin_TX: {
        lat: 30.266926,
        lon: -97.750519,
      },
    };
  },
  async created() {
    //this.isCreated = true;

    if (this.toogleIntroSplashHwi == false) {
      if (this.visto == true) {
        this.toogleIntroSplash = false;
      } else if (this.visto == false) {
        this.toogleIntroSplash = true;
      }
    }

    if (
      this.$route.query.v &&
      this.$route.query &&
      this.$route.query.v == "true"
    ) {
      this.isModalVisible = true;
      this.toogleIntroSplash = false;
    }



    //this.toogleIntroSplash = false //para quitar los splash
    //this.toogleIntroSplashHwi = false
  },

  async mounted() {
    this.$nextTick(async () => {

      this.$store.commit("checkout/selectedTicketGroup", null)

      //facebook.facebook(true)
      /*setTimeout(()=>{
        FB.XFBML.parse(); 
        FB?.CustomerChat?.hide();
      }, 500)*/




      this.$store.commit("ClickBody", true);
      document.getElementsByTagName("html")[0].style.overflowY = "";
      document.body.style.paddingRight = "0";

      this.showVIPOnboard();

      this.verifyFavoriteByEvent();


      window.extAsyncInit = function () {


        // the Messenger Extensions JS SDK is done loading
        /*MessengerExtensions.getSupportedFeatures(function success(result) {
              let features = result.supported_features;
              if (features.indexOf("context") != -1) {
                MessengerExtensions.getContext('1580071112297891',
                  function success(thread_context) {
                    // success


                    // More code to follow
                  },
                  function error(err) {

                  }
                );
              }
            }, function error(err) {

            });*/
      };

      //this.$lozad.observe();
      var vm = this;

      window.addEventListener("scroll", this.handleScroll);
      document.addEventListener('mousemove', this.initLoadEvents);
      document.addEventListener('touchstart', this.initLoadEvents);

      if (
        this.$route.query.v &&
        this.$route.query &&
        this.$route.query.v == "true"
      ) {
        this.isModalVisible = true;
        this.toogleIntroSplash = false;
      }

      if (this.isModalVisible == true) {

      }

      if (this.toogleIntroSplash == true || this.toogleIntroSplashHwi == true) {
        /*const el = document.body;
        el.classList.add("modal-open");*/
        //TODO:
      }

      this.invitation_code = this.user
        ? `?invitation_code=${this.user._id}`
        : "";
      if (this.media_match === 0) {
        //primera screen and (max-width: 600px)
        this.carrusel_per_page = 1;
      } else if (this.media_match === 1) {
        //segunda screen and (min-width: 601px) and (max-width: 767px)
        this.carrusel_per_page = 2;
      } else if (this.media_match === 2) {
        //tercera screen and (min-width: 768px) and (max-width: 991px)
        this.carrusel_per_page = 2;
      } else if (this.media_match === 3) {
        //cuarta screen and (min-width: 992px) and (max-width: 1199px)
        this.carrusel_per_page = 3;
      } else if (this.media_match === 4) {
        //quinta screen and (min-width: 1200px)
        this.carrusel_per_page = 3;
      }

      setTimeout(() => {
        this.showHWi = true;
      }, 1000);
      this.$store.commit("storeCoordinates", { lat: this.lat, lon: this.lon });


      setTimeout(() => {
        if (window.OneSignal) {
          /*window.OneSignal.on("notificationPermissionChange", permissionChange => {
              var currentPermission = permissionChange.to;

            });

            window.OneSignal.on("subscriptionChange", isSubscribed => {

            });*/

          try {
            window.OneSignal.push(() => {
              Promise.all([
                this.$OneSignal.isPushNotificationsEnabled(),
                window.OneSignal.getUserId(),
                window.OneSignal.getRegistrationId(),
                window.OneSignal.getNotificationPermission(),
                window.OneSignal.isOptedOut(),
                //this.$OneSignal.context.serviceWorkerManager.getActiveState()
              ])
                .then(
                  ([
                    isSubscribed,
                    userId,
                    registrationId,
                    notificationPermission,
                    optedOut,
                    //serviceWorkerActive
                  ]) => {
                    window.OneSignal.setSubscription(true);
                  }
                )
                .catch((e) => {
                  console.error(
                    "Issue determining whether push is enabled:",
                    e
                  );
                });
            });
          } catch (error) { }
        }
      }, 5000);
      if (!this.activePromoCode) {
        this.activatePromoCode()
      }

      if (
        this.$route.query.login &&
        this.$route.query &&
        this.$route.query.login == "true"
      ) {
        this.openSignIn()
      }

      if (
        this.$route.query['reset-pass'] &&
        this.$route.query &&
        this.$route.query['reset-pass'] == "true"
      ) {
        this.openSignInResetPassMode()
      }


    });
  },

  watch: {
    magic_flag(newVal) {
      if (newVal) {
        this.showConcertsMenu = false
        this.showSportsMenu = false
        this.showMoreMenu = false
      }

    },
    position(newVal) {

      if (newVal[1] > 200) {
        this.showInner = false
      } else {
        this.showInner = true
      }
    },
    homeCards: function (newVal) { },
    openTrackArtist: function (newVal) {
      if (newVal == true) {
        this.showModal();
      }
    },
    isAutenticated: function (newVal) {
      this.verifyFavoriteByEvent();
    },
    prospect: async function (newVal) {
      if (this.prospect === true) {
        return this.$store.commit("eventcards/deleteAllFavorites");
      } else {
        if (this.isAutenticated) {
          this.verifyFavoriteByEvent();
        }
      }
    },

    userIsVIPMember: function (newVal) {
      if (newVal == true) {
        this.showVIPOnboard();
      }
    },
    isModalVisible: function (newVal) {

    },
    openCategoriesMenu: function (newVal) {
      if (newVal == true) {
        this.showConcertsMenu = false
        this.showSportsMenu = false
        this.showMoreMenu = false
      }
    },

    toogleIntroSplash: function (newVal) {
      let className = "modal-open";

      if (newVal) {

      } else {

      }
    },
    toogleIntroSplashHwi: function (newVal) {
      let className = "modal-open";

      if (newVal) {

      } else {
        if (this.isModalVisible == false) {

        }
      }
    },

    media_match(newValue, oldValue) {
      if (newValue === 0) {
        //primera screen and (max-width: 600px)
        this.carrusel_per_page = 1;
      } else if (newValue === 1) {
        //segunda screen and (min-width: 601px) and (max-width: 767px)
        this.carrusel_per_page = 2;
      } else if (newValue === 2) {
        //tercera screen and (min-width: 768px) and (max-width: 991px)
        this.carrusel_per_page = 2;
      } else if (newValue === 3) {
        //cuarta screen and (min-width: 992px) and (max-width: 1199px)
        this.carrusel_per_page = 3;
      } else if (newValue === 4) {
        //quinta screen and (min-width: 1200px)
        this.carrusel_per_page = 3;
      }
    },
  },
  methods: {

    async activatePromoCode() {

      let body = {};
      if (this.$route.query.promocode) {
        body.code = this.$route.query.promocode;
      }

      await this.$store.dispatch("checkout/verifyPromoCode", body)
      this.$store.commit("signinform/toogleSignUp", true);
      this.$store.commit("showPromoToast", true);


    },

    clickGetCodePromoRegister() {
      this.$store.commit("signinform/signUpModePromo", false)
      this.$store.commit("signinform/toogleSignUp", true)
      this.$store.commit("toogleSignIn", true)
      this.$store.commit("showPromoToast", false)
    },
    initLoadEvents(event) {
      if (this.homeCards.length < 8) {
        this.showMeMoreClick();
      }



      //this.$gtm.init('GTM-P63HHPN')

      /*setTimeout(()=>{
       FB.XFBML.parse(); 
       FB?.CustomerChat?.hide();
     }, 500)*/

      /*facebook.facebook(true, ()=>{
        //FB.XFBML.parse();  
        FB?.CustomerChat?.hide();  
        console.log('ocultar chat')
      })*/

      event.currentTarget.removeEventListener(event.type, this.initLoadEvents); // remove the event listener that got triggered

    },


    openMobileSearchClick() {
      this.$store.commit("openMobileSearch", true);
    },


    turnOnToogleSignIn() {

    },

    showMeMoreClick() {
      let eventsPromises = [];
      let query = {};

      if (this.queryBase.per_page < 10) {
        query = { ...this.queryBase, per_page: 10 };

        this.$store.commit("eventcards/queryBase", query);
      }
      let fin = 30 / this.queryBase.per_page - 1;
      let page = this.queryBase.page;
      if (this.homeCards.length == 0) {
        page = 0;
        fin++;
      }

      for (let i = 1; i <= fin; i++) {
        page = page + 1;
        query = { ...this.queryBase, page: page };

        eventsPromises.push(eventsApi.actionGetEventTevoQuery(query));
      }

      this.$store.commit("eventcards/queryBase", query);
      let eventsBeforeLength = this.homeCards.length;
      let eventsAfterLength = this.homeCards.length;
      Promise.all(eventsPromises)
        .then((promisesRes) => {
          promisesRes.forEach((newCards) => {
            this.$store.commit("eventcards/addMoreCards", newCards);
          });
          eventsBeforeLength = this.homeCards.length;
          console.log(this.homeCards.length);
          if (eventsBeforeLength === eventsAfterLength) {
            this.show_more = false;
          } else if (eventsBeforeLength <= eventsAfterLength) {
            this.show_more = true;
          }
          this.verifyFavoriteByEvent();
        })
        .catch((error) => { });
    },

    showVIPOnboard() {
      if (
        this.userIsVIPMember == true &&
        this.vipSplashVisto == false &&
        Cookie.get("vipSplashVisto") != "true"
      ) {
        //&& this.numVistasHwi <= 2
        this.toogleIntroSplashHwi = true;

        Cookie.set("vipSplashVisto", "true");
        this.$store.commit("vipSplashVisto", true);
      }
    },
    swipeContent(e) { },
    getRuta(event) {
      return microformatos.getRuta(event);
    },
    onload() { },

    handleScroll: function (event) {
      var x = document.getElementsByClassName("container_content");
      var y = document.getElementsByClassName("trackartist");
      var z = document.getElementsByClassName("floating_button_bottom");
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      var currentScrollPosition = event.target.scrollingElement.scrollTop;


      if (
        currentScrollPosition > this.finalCurrentScrollPosition + 500 &&
        this.homeCards.length <= 40
      ) {
        this.finalCurrentScrollPosition = currentScrollPosition;
        if (this.homeCards.length < 8) { this.showMeMoreClick(); }

      }



      if (currentScrollPosition > 0) {
        this.scroll_on_track_artist = true;
        return
        if (window.innerWidth <= 768) {
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            if (
              window.innerHeight + event.target.scrollingElement.scrollTop >
              document.body.clientHeight - 90
            ) {
              var calc_padding_bottom = Math.abs(
                window.innerHeight +
                event.target.scrollingElement.scrollTop -
                (document.body.clientHeight - 90)
              );
              var calc_margin_bottom =
                (16 + Math.ceil(calc_padding_bottom)) / 106;
              var margin_bottom = 0;
              if (calc_margin_bottom >= 1) {
                margin_bottom = 106;
              } else {
                margin_bottom = Math.ceil(16 + calc_padding_bottom);
              }

              z[0].style.margin = "0px 16px " + margin_bottom + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          } else {
            if (
              event.target.scrollingElement.clientHeight +
              event.target.scrollingElement.scrollTop >
              event.target.scrollingElement.scrollHeight - 90
            ) {
              var calc_padding_bottom = Math.abs(
                event.target.scrollingElement.clientHeight +
                event.target.scrollingElement.scrollTop -
                (event.target.scrollingElement.scrollHeight - 90)
              );
              z[0].style.margin =
                "0px 16px " + (16 + calc_padding_bottom) + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          }
        } else {
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            if (
              window.innerHeight + event.target.scrollingElement.scrollTop >
              document.body.clientHeight - 64
            ) {
              var calc_padding_bottom = Math.abs(
                window.innerHeight +
                event.target.scrollingElement.scrollTop -
                (document.body.clientHeight - 64)
              );
              var calc_margin_bottom = (16 + calc_padding_bottom) / 106;
              var margin_bottom = 0;
              if (calc_margin_bottom >= 1) {
                margin_bottom = 80;
              } else {
                margin_bottom = Math.ceil(16 + calc_padding_bottom);
              }
              z[0].style.margin = "0px 16px " + margin_bottom + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          } else {
            if (
              event.target.scrollingElement.clientHeight +
              event.target.scrollingElement.scrollTop >
              event.target.scrollingElement.scrollHeight - 64
            ) {
              var calc_padding_bottom = Math.abs(
                event.target.scrollingElement.clientHeight +
                event.target.scrollingElement.scrollTop -
                (event.target.scrollingElement.scrollHeight - 64)
              );
              z[0].style.margin =
                "0px 16px " + (16 + calc_padding_bottom) + "px 0px";
            } else {
              z[0].style.margin = "0px 16px 16px 0px";
            }
          }



        }

        if (window.innerWidth < 600) {
          y[0].innerHTML = "";
        } else {
          y[0].innerHTML = "";
        }
      } else {

        this.scroll_on_track_artist = false;

        return
        y[0].innerHTML =
          '<span class="track-artist-icon-span"></span>FOLLOW ARTISTS';
      }
    },
    hiwStart() {
      if (this.isAutenticated == false) {

      } else {
        this.isModalVisible = true;
      }
    },
    ClickBody() {
      this.$store.commit("ClickBody", true); // !this.$store.getters.isClickBody
      this.showConcertsMenu = false
      this.showSportsMenu = false
      this.showMoreMenu = false


      scroll.blockScroll(false)


      const allBannerDropdownMenuFirstLevel = document.querySelectorAll(".banner_dropdown_menu_first_level");
      for (let index = 0; index < allBannerDropdownMenuFirstLevel.length; index++) {
        const element = allBannerDropdownMenuFirstLevel[index];
        element.classList.remove("active");
      }
    },
    facebookShareLink(name) {
      let share = `${appFrontUrl}/book/${name}`;
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        share
      )}&amp;src=sdkpreparse`;
    },
    fbShareDialog(name) {
      FB.ui(
        {
          method: "share",
          href: `${appFrontUrl}/book/${name}`,
        },
        function (response) { }
      );
    },
    favoriteArtistClick(artist, index) {
      artist.isFavorite = !artist.isFavorite;

      let newArtists = {
        performer_id: artist.id,
        name: artist.name,
        images: [],
        popularity_score: artist.popularity_score,
        category_id: artist.category.id,
        category_name: artist.category.name,
        activado: true,
      };

      let datos = {
        performers: [newArtists],
        category_id: 54,
      };

      if (this.isAutenticated == false) {
        artist.isFavorite = false;

        this.$store.dispatch("user/setPerformersToSave", datos);
        return;
      } else {
      }

      if (artist.isFavorite == true) {
        this.$store
          .dispatch("user/setUserPerformers", datos)
          .then((response) => {
            this.artists[index].isFavorite = true;
            Vue.set(this.artists, index, artist);

            //this.verifyFavorite();
          })
          .catch((error) => { });
      } else {
        let borrar = [];

        borrar.push(artist);
        this.$store
          .dispatch("user/deleteUserSeleccionados", borrar)
          .then((response) => {
            this.artists[index].isFavorite = false;
            Vue.set(this.artists, index, artist);

            this.verifyFavorite();
          })
          .catch((error) => { });
      }
    },
    /**
     * ===============
     * favoriteClick
     * ===============
     */

    favoriteClick(event, index) {
      let category_id = 0;
      if (event.category) {
        if (event.category.id) {
          if (event.category.parent) {
            if (event.category.parent.parent) {
              if (event.category.parent.parent.id) {
                category_id = event.category.parent.parent.id;
              } else {
                category_id = event.category.parent.id;
              }
            } else {
              category_id = event.category.parent.id;
            }
          } else {
            category_id = event.category.id;
          }
        }
      }

      let categoria_id = 0;
      let categoria_nombre = "";
      if (event.performances.performer.category) {
        categoria_id = event.performances.performer.category.id;
        categoria_nombre = event.performances.performer.category.name;
      }
      let newArtists = {
        performer_id: event.performances.performer.id,
        name: event.performances.performer.name,
        images: [],
        popularity_score: event.performances.performer.popularity_score,
        category_id: categoria_id,
        category_name: categoria_nombre,
        activado: true,
      };

      let datos = {
        performers: [newArtists],
        category_id: category_id,
      };

      if (this.isAutenticated == false || (this.isAutenticated == true && this.prospect == true)) {

        this.$store.dispatch("user/setPerformersToSave", datos);
        return;
      } else {
      }

      event.isFavorite = !event.isFavorite;

      //this.tevo5NextDaysCarrusel.eventos[index].isFavorite = event.isFavorite;
      this.$store.commit("eventcards/updateHomeCard", event);

      //this.isFavorite = !this.isFavorite;

      if (event.isFavorite == true) {
        this.$store
          .dispatch("user/setUserPerformers", datos)
          .then((response) => {
            this.verifyFavoriteByEvent();
          })
          .catch((error) => { });
      } else {
        let borrar = [];

        borrar.push(newArtists);
        this.$store
          .dispatch("user/deleteUserSeleccionados", borrar)
          .then((response) => {
            this.verifyNoFavoriteByEvent(event.performances.performer.id);
          })
          .catch((error) => { });
      }
    },
    /**
     * ========================
     * verifyFavorite
     * ========================
     *
     */
    verifyFavorite() {
      if (this.isAutenticated == true) {
        this.$store.dispatch("user/myArtists").then((artists) => {
          this.myArtists = artists;

          if (this.myArtists.length > 0 && this.artists.length > 0) {
            for (let i = 0; i < this.myArtists.length; i++) {
              for (let j = 0; j < this.artists.length; j++) {
                if (this.myArtists[i].performer_id == this.artists[j].id) {
                  this.artists[j].isFavorite = true;

                  Vue.set(this.artists, j, this.artists[j]);
                }
              }
            }
          }
        });
      } else {
        for (let j = 0; j < this.artists.length; j++) {
          this.artists[j].isFavorite = false;

          Vue.set(this.artists, j, this.artists[j]);
        }
      }
    },

    /**
     * ========================
     * verifyFavoriteByEvent
     * ========================
     *
     */
    async verifyFavoriteByEvent() {
      if (this.isAutenticated == false || this.prospect == true) {
        this.$store.commit("eventcards/deleteAllFavorites");
        return
      }
      await this.$store.dispatch("user/updateUser")

      let user = this.user;
      let artists = user.music;
      this.myArtists = artists;

      let sports = [];
      let theatre = [];
      let special_events = [];

      if (user.sports.length > 0) {
        sports = user.sports;
        this.myArtists = this.myArtists.concat(sports);
      }
      if (user.theatre.length > 0) {
        theatre = user.theatre;
        this.myArtists = this.myArtists.concat(theatre);
      }
      if (user.special_events.length > 0) {
        special_events = user.special_events;
        this.myArtists = this.myArtists.concat(special_events);
      }

      if (this.myArtists.length > 0 && this.homeCards.length > 0) {
        for (let i = 0; i < this.myArtists.length; i++) {
          let data = {
            performerId: this.myArtists[i].performer_id,
            isFavorite: true,
          };
          this.$store.commit("eventcards/updateIsFavorite", data);
        }
      }

    },

    /**
     * ========================
     * verifyNoFavoriteByEvent
     * ========================
     *
     */
    verifyNoFavoriteByEvent(performer_id) {
      if (this.isAutenticated == true) {
        for (let j = 0; j < this.tevo5NextDaysCarrusel.eventos.length; j++) {
          if (
            performer_id ==
            this.tevo5NextDaysCarrusel.eventos[j].performances.performer.id
          ) {
            this.tevo5NextDaysCarrusel.eventos[j].isFavorite = false;
            Vue.set(
              this.tevo5NextDaysCarrusel.eventos,
              j,
              this.tevo5NextDaysCarrusel.eventos[j]
            );
          }
        }
      }
    },
    performerData() {
      this.cargando = true;
      this.tevo5NextDaysCarrusel.eventos = [];
      this.showArtists = true;

      this.locality = "North America";

      let performers = this.morePopularPerformers;

      this.cargando = false;
      for (let i = 0; i < performers.length; i++) {
        performers[i].performer_id = performers[i].id;
        performers[i].activado = false;
        performers[i].isFavorite = false;

        if (i === performers.length - 1) {
          this.artists = performers;
          this.morePopularPerformers = this.artists;
          this.verifyFavorite();
        }
      }

      for (let i = 0; i < this.artists.length; i++) {
        this.artists[i].venue = {
          location: "Searching event",
          name: "Searching event",
        };
        this.artists[i].occurs_at_format = "Searching event";

        if (
          this.artists[i].imageUrl ===
          "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg"
        ) {
          this.upatePerformer(this.artists[i]);
        }

        this.getFirstEventByPerformer(this.artists[i], i);
      }
    },


    toBook(performer) {
      window.fbq("trackCustom", "Home-Click", {
        performer: performer.name,
        id: performer.id,
      });

      //this.$router.push(`/book/${performer.name}`);

      window.location.href = `book/${performer.name}`;
    },
    upatePerformer(event) {
      //let performer_id = event.performances.performer.id;

      let performer_id = event.id;

      this.$store
        .dispatch("performers/actionUpdatePerformerById", {
          performer_id,
        })
        .then((response) => {
          event.imageUrl = response.data.imageUrl;
        });
    },
    eventsTicketMaster() {
      let query = `page=0&size=8&lat=${this.position.latitude}&lon=${this.position.longitude}&radius=300`;

      this.$store
        .dispatch("events/actionGetEventTicketMasterQuery", query)
        .then((response) => {
          let page = response.data.page;
          let eventos = response.data.eventos;

          this.tmByLocationCarrusel.eventos = eventos;
        });
    },
    /**
     * ======================================================================================
     * @description función ejecutada cuando no se tiene acceso a las posición del usuario
     * ======================================================================================
     */
    errorShowPosition(err) {
      this.performerData();
    },
    /**
     * ======================================================================================
     * @description funcion que se ejecuta  para obtener la position geográfica showPosition
     * ======================================================================================
     */
    async showPosition(position) {
      var vm = this;

      vm.position.latitude = position.coords.latitude;
      vm.position.longitude = position.coords.longitude;
    },
    /*
     * ===========================
     *  getFirstEventByPerformer
     * ===========================
     */
    getFirstEventByPerformer(artist, index) {
      let search = {
        //min_and_max_price: true,
        performer_id: artist.performer_id,
        //only_with_tickets: "all",
        only_with_available_tickets: true,
        order_by: "events.occurs_at ASC",
        page: 1,
        per_page: 1,
      };

      this.$store
        .dispatch("events/actionGetEventTevoQuery", search)
        .then((response) => {
          if (response.data[0] && response.data[0].venue) {
            artist.venue = response.data[0].venue;
            Vue.set(artist, "venue", response.data[0].venue);
            Vue.set(
              artist,
              "occurs_at_format",
              response.data[0].occurs_at_format
            );
            Vue.set(this.artists, index, artist);
          } else {
            artist.venue = {
              location: "Not Found",
              name: "Not Found",
            };
            artist.occurs_at_format = "Not Found";
          }
        });
    },
    /**
     * ===========================
     * getEventsPorPopularidad
     * ===========================
     */
    getEventsPorPopularidad() {
      let search = {
        category_id: 54,
        category_tree: true,
        //min_and_max_price: true,
        only_with_tickets: "all",
        only_with_available_tickets: true,
        order_by: "events.occurs_at ASC",
        order_by: "events.popularity_score DESC",
        page: 1,
        per_page: 16,
      };

      this.$store
        .dispatch("events/actionGetEventTevoQuery", search)
        .then((response) => {
          if (response.data.length > 0) {
            this.tevo5NextDaysCarrusel.eventos = response.data;

            if (
              this.tevo5NextDaysCarrusel.eventos[0] &&
              this.tevo5NextDaysCarrusel.eventos[0].venue &&
              this.tevo5NextDaysCarrusel.eventos[0].venue.location
            ) {
              this.locality = this.tevo5NextDaysCarrusel.eventos[0].venue.location;
            }

            for (
              let i = 0;
              i < this.tevo5NextDaysCarrusel.eventos.length;
              i++
            ) {
              if (
                this.tevo5NextDaysCarrusel.eventos[i].imageUrl ===
                "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg"
              )
                this.tevo5NextDaysCarrusel.eventos[i].isFavorite = false;

              this.upatePerformer(this.tevo5NextDaysCarrusel.eventos[i]);
            }
          } else {
            this.tevo5NextDaysCarrusel.eventos = [];
            this.eventsTicketMaster();
          }
        });
    },
    showModal() {
      const el = document.body;
      el.classList.add("modal-open");
      this.isModalVisible = true;
    },
    closeModal() {
      const el = document.body;
      el.classList.remove("modal-open");
      this.isModalVisible = false;
    },
    alertaCordenadas() {
      alert(`lat ${this.position.latitude} lon ${this.position.longitude}`);
    },

    mediaQueries(alias, matches, init = false) {
      if (alias === "primera" && matches) {
        this.carrusel_per_page = 1;
      } else if (alias === "segunda" && matches) {
        this.carrusel_per_page = 2;
      } else if (alias === "tercera" && matches) {
        this.carrusel_per_page = 3;
      } else if (alias === "cuarta" && matches) {
        this.carrusel_per_page = 3;
      }
    },
    closeSignUp() {
      this.toogleSignUp = false;
    },
    openSignUp() {
      this.toogleSignUp = true;
    },
    closeSignIn() {

    },
    closeNotification() {
      this.toogleNotification = false;
    },

    closeIntroSplash() {
      this.toogleIntroSplash = false;
    },
    closeIntroSplashHwi() {
      this.toogleIntroSplashHwi = false;
    },
    openSignIn() {
      this.$store.commit("signinform/signUpModePromo", false)
      this.$store.commit("signinform/toogleSignUp", false)
      this.$store.commit("userPromoAdded", false);
      this.$store.commit("showPromoToast", false)
      this.$store.commit("toogleSignIn", true)
      this.$store.commit("ClickBody", false)
      scroll.blockScroll(true)
    },
    openSignInResetPassMode() {
      this.$store.commit("signinform/signUpModePromo", false)
      this.$store.commit("signinform/toogleSignUp", false)

      this.$store.commit("signinform/recoveryToogle", true)
      this.$store.commit("signinform/recoverySendEmail", false)

      this.$store.commit("userPromoAdded", false);
      this.$store.commit("showPromoToast", false)
      this.$store.commit("toogleSignIn", true)
      this.$store.commit("ClickBody", false)
      scroll.blockScroll(true)
    },


    errorArtistImage(artist, index) {
      this.artists[index].imageUrl =
        "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";
    },
    errorEventImage(event, index) {
      event.imageUrl =
        "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";

      Vue.set(this.tevo5NextDaysCarrusel.eventos, index, event);

      this.$store
        .dispatch("performers/actionUpdatePerformerById", {
          performer_id: event.performances.performer.performer_id,
        })
        .then((response) => {
          event.imageUrl = response.data.imageUrl;
        })
        .catch((e) => { });
    },
    enableScroll() {
      scroll.blockScroll(false)
      this.showConcertsMenu = false
      this.showSportsMenu = false
      this.showMoreMenu = false

      const allBannerDropdownMenuFirstLevel = document.querySelectorAll(".banner_dropdown_menu_first_level");
      for (let index = 0; index < allBannerDropdownMenuFirstLevel.length; index++) {
        const element = allBannerDropdownMenuFirstLevel[index];
        element.classList.remove("active");
      }

    },

    disableScroll() {
      //scroll.blockScroll(true)
    },
    onClickConcertsMenu(e) {
      this.showConcertsMenu = !this.showConcertsMenu
      this.showSportsMenu = false
      this.showMoreMenu = false
      this.$store.commit("openCategoryMenu", true)
      this.$store.commit("openCategoriesMenu", false)

      document.getElementsByTagName("html")[0].style.overflowY = "hidden";



      const allBannerDropdownMenuFirstLevel = document.querySelectorAll(".banner_dropdown_menu_first_level");
      for (let index = 0; index < allBannerDropdownMenuFirstLevel.length; index++) {
        const element = allBannerDropdownMenuFirstLevel[index];
        element.classList.remove("active");
      }
      if (this.showConcertsMenu) {
        e.target.classList.add("active");
      }
    },
    onClickSportsMenu(e) {
      this.showConcertsMenu = false
      this.showSportsMenu = !this.showSportsMenu
      this.showMoreMenu = false
      this.$store.commit("openCategoryMenu", true)
      this.$store.commit("openCategoriesMenu", false)

      document.getElementsByTagName("html")[0].style.overflowY = "hidden";


      const allBannerDropdownMenuFirstLevel = document.querySelectorAll(".banner_dropdown_menu_first_level");
      for (let index = 0; index < allBannerDropdownMenuFirstLevel.length; index++) {
        const element = allBannerDropdownMenuFirstLevel[index];
        element.classList.remove("active");
      }
      if (this.showSportsMenu) {
        e.target.classList.add("active");
      }
    },
    onClickMoreMenu(e) {
      this.showConcertsMenu = false
      this.showSportsMenu = false
      this.showMoreMenu = !this.showMoreMenu
      this.$store.commit("openCategoryMenu", true)
      this.$store.commit("openCategoriesMenu", false)

      document.getElementsByTagName("html")[0].style.overflowY = "hidden";

      const allBannerDropdownMenuFirstLevel = document.querySelectorAll(".banner_dropdown_menu_first_level");
      for (let index = 0; index < allBannerDropdownMenuFirstLevel.length; index++) {
        const element = allBannerDropdownMenuFirstLevel[index];
        element.classList.remove("active");
      }
      if (this.showMoreMenu) {
        e.target.classList.add("active");
      }
    },
    onClickMenuDropdown(e) {
      const bannerDropdownMenuSecondLevel = e.target.nextElementSibling;
      const allBannerDropdownMenuSecondLevel = document.querySelectorAll(".banner_dropdown_menu_item .row");
      for (let index = 0; index < allBannerDropdownMenuSecondLevel.length; index++) {
        const element = allBannerDropdownMenuSecondLevel[index];
        element.classList.remove("active");
      }
      bannerDropdownMenuSecondLevel.classList.add("active");
    },
    onClickHiddenMenuDropdown(e) {


      document.getElementsByTagName("html")[0].style.overflowY = "";

      const allBannerDropdownMenuSecondLevel = document.querySelectorAll(".banner_dropdown_menu_item .row");
      for (let index = 0; index < allBannerDropdownMenuSecondLevel.length; index++) {
        const element = allBannerDropdownMenuSecondLevel[index];
        element.classList.remove("active");
      }
    }
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.handleScroll);

    scroll.blockScroll(false)

    this.$store.commit("ClickBody", true);
  },
};
