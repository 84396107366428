
/* eslint-disable */

import { mapMutations, mapGetters } from "vuex";
import homeEvents from "@/api/home/index";
import api from "@/api/api";
import scroll from '@/util/scroll'

export default {
  speedkitComponents: {
    SpeedEventCardG: () => import('@/components/EventCards/EventCardG'),
  
  },
  computed: {
    ...mapGetters({
      homeCards: "eventcards/homeCards",
      locality: "eventcards/locality",
      queryBase: "eventcards/queryBase",
      isAutenticated: "user/isAutenticated",
      prospect: "user/prospect",
    })
  },

  

  mounted(){
    this.verifyFavoriteByEvent();

  },
  watch: {
    isAutenticated: function(newVal) {
      this.verifyFavoriteByEvent();
    },
    prospect: async function(newVal){
      if(this.prospect === true){
        return  this.$store.commit("eventcards/deleteAllFavorites"  );
      }else{
       if(this.isAutenticated){
        this.verifyFavoriteByEvent();
      }
       
     }
    },

  },
  methods: {

    openSignIn() {
      this.$store.commit("signinform/signUpModePromo", false ) 
      this.$store.commit("signinform/toogleSignUp", false ) 
       this.$store.commit("userPromoAdded", false);
      this.$store.commit("showPromoToast", false ) 
      this.$store.commit("toogleSignIn", true )
      this.$store.commit("ClickBody", false )
      scroll.blockScroll(true)
     
    },
    favoriteClick(event, index) {
      console.log('favoriteClick')
      let category_id = 0;
      if (event.category) {
        if (event.category.id) {
   
          if (event.category.parent) {
          
            if (event.category.parent.parent) {
            
              if (event.category.parent.parent.id) {
          
                category_id = event.category.parent.parent.id;
              } else {
           
                category_id = event.category.parent.id;
              }
            } else {
      
              category_id = event.category.parent.id;
            }
          } else {
      
            category_id = event.category.id;
          }
        }
      }
 
      let categoria_id = 0;
      let categoria_nombre = "";
      if (event.performances.performer.category) {
        categoria_id = event.performances.performer.category.id;
        categoria_nombre = event.performances.performer.category.name;
      }

      
       

      let newArtists = {
        performer_id: event.performances.performer.id,
        name: event.performances.performer.name,
        images: [],
        popularity_score: event.performances.performer.popularity_score,
        category_id: categoria_id,
        category_name: categoria_nombre,
        activado: true
      };

      let datos = {
        performers: [newArtists],
        category_id: category_id
      };

      if (this.isAutenticated == false || (this.isAutenticated == true && this.prospect == true )) {
        //this.toogleSignIn = true;
        this.$store.dispatch("user/setPerformersToSave", datos);
        console.log('abrir el SignIn')
        this.openSignIn()
        return  this.$emit('toogleSignIn'  ) //abrir el SignIn
      } else {
      }
    
      //event.isFavorite = !event.isFavorite;

      let newEvvent = { ...event, isFavorite: !event.isFavorite  }
 


      //this.tevo5NextDaysCarrusel.eventos[index].isFavorite = event.isFavorite;
      this.$store.commit("eventcards/updateHomeCard", newEvvent ); 

      //this.isFavorite = !this.isFavorite;

      if (newEvvent.isFavorite == true) {
        this.$store
          .dispatch("user/setUserPerformers", datos)
          .then(response => {
 

            this.verifyFavoriteByEvent();
          })
          .catch(error => {
         
          });
      } else {
        let borrar = [];

        borrar.push(newArtists);
        this.$store
          .dispatch("user/deleteUserSeleccionados", borrar)
          .then(response => {
        
            //this.verifyNoFavoriteByEvent(event.performances.performer.id);
          })
          .catch(error => {
          
          });
      }
    },


    async verifyFavoriteByEvent() {
 
      if (this.isAutenticated == false || this.prospect == true) {
          this.$store.commit("eventcards/deleteAllFavorites"  );
          return
      }
 
      await this.$store.dispatch("user/updateUser")
      if (!this.user)return 
      
      let user = this.user;
      let artists = user.music;
      this.myArtists = artists;

      let sports = [];
      let theatre = [];
      let special_events = [];

      if (user.sports.length > 0) {
        sports = user.sports;
        this.myArtists = this.myArtists.concat(sports);
      }
      if (user.theatre.length > 0) {
        theatre = user.theatre;
        this.myArtists = this.myArtists.concat(theatre);
      }
      if (user.special_events.length > 0) {
        special_events = user.special_events;
        this.myArtists = this.myArtists.concat(special_events);
      }

      if (
        this.myArtists.length > 0 &&
        this.homeCards.length > 0
      ) {
        for (let i = 0; i < this.myArtists.length; i++) {
          let data = { performerId: this.myArtists[i].performer_id, isFavorite: true };
          this.$store.commit("eventcards/updateIsFavorite", data );
        }
      }
    }
  }
}

  

