
import configVars from "@/config/config_vars";

import moment from "moment"
let appFrontUrl = configVars.appFrontUrl;

var cityList = () => {
  const list = [{
    url: `${appFrontUrl}cities/los-angeles-ca/`,
    text: `Los Angeles`
  },
  {
    url: `${appFrontUrl}cities/las-vegas-nv/`,
    text: `Las Vegas`
  },
  {
    url: `${appFrontUrl}cities/chicago-il/`,
    text: `Chicago`
  },
  {
    url: `${appFrontUrl}cities/san-antonio-tx/`,
    text: `San Antonio`
  },
  {
    url: `${appFrontUrl}cities/new-york-ny/`,
    text: ` New York`
  },
  ]

  return breadcrumbList(list)


}

const search = () => {
  return {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": `${appFrontUrl}`,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${appFrontUrl}/search?q={search_term_string}`,
      "query-input": "required name=search_term_string"
    }
  }
}
var organization = () => {
  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Pepper Tix",
    "alternateName": "My Pepper Bot",
    "url": "https://peppertix.org",
    "logo": "https://peppertix.org/img/home/pepper_bot_128.png",
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "16502721641",
      "contactType": "customer service",
      "areaServed": "US",
      "availableLanguage": ["en", "es"]
    }, {
      "@type": "ContactPoint",
      "telephone": "16502721641",
      "contactType": "technical support",
      "areaServed": "US",
      "availableLanguage": ["en", "es"]
    }],
    "sameAs": [
      "https://www.facebook.com/mypepperbot",
      "https://twitter.com/intent/user?screen_name=thepeppertix",
      "https://www.instagram.com/thepeppertix/",
      "https://www.youtube.com/channel/UCAtsuNj75xKFA-KtqGkM_AA",
      "https://www.messenger.com/t/mypepperbot"
    ]
  }
}

var breadcrumbList = (breadcrumbs = [{ url, text }]) => {

  let itemListElements = breadcrumbs.map((item, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    item: {
      '@id': item.url,
      name: item.text,
    },
  }));

  return {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": itemListElements
  }
}


var getEventsStructuredData = (events) => {
  let structuredData = []



  events.forEach(function (evento) {

    if (evento.min_price) {
      let min_price_f = parseFloat(evento.min_price).toFixed(0);
      let performerName = ''
      if (Array.isArray(evento.performances)) {
        performerName = evento.performances[0].performer.name;
      } else {
        performerName = evento.performances.performer.name;
      }

      let perfromerGroupName = performerName

      performerName = performerName.replace(/ /gi, "-");
      performerName = performerName.replace(/,/gi, "-");
      performerName = performerName.replace(/--/gi, "-");
      performerName = performerName.toLowerCase();

      let venueLocation = evento.venue.location;
      venueLocation = venueLocation.replace(/ /gi, "-");
      venueLocation = venueLocation.replace(/,/gi, "-");
      venueLocation = venueLocation.replace(/--/gi, "-");
      venueLocation = venueLocation.toLowerCase();

      let venueName = evento.venue.name;
      venueName = venueName.replace(/ /gi, "-");
      venueName = venueName.replace(/,/gi, "-");
      venueName = venueName.replace(/--/gi, "-");
      venueName = venueName.toLowerCase();

      let hour = evento.hour;
      hour = hour.replace(/ /gi, "-");
      hour = hour.replace(/:/gi, "-");
      let eventFecha = `${evento.year}-${evento.month2}-${evento.day2
        }-${hour}`;

      let ruta = `${appFrontUrl}book/${performerName}/${venueLocation}-${venueName}-${eventFecha}/${evento.id
        }`;
      let itemStructure = {
        "@context": "http://schema.org/",
        "@type": "Event",
        name: evento.name,
        description: evento.name,
        startDate: evento.occurs_at,
        endDate: evento.occurs_at,
        location: {
          "@type": "Place",
          name: evento.venue.name,
          address: {
            streetAddress: evento.venue.name,
            addressLocality: evento.venue.location,
            addressRegion: ""
          }
        },
        performer: {
          "@type": "PerformingGroup",
          name: perfromerGroupName
        },
        offers: {
          "@type": "Offer",
          availability: "https://schema.org/InStock",
          price: min_price_f,
          validFrom: moment(),
          url: ruta,
          priceCurrency: "USD"
        }
      };



      structuredData.push(itemStructure);
    }


  });

  return structuredData
}




let getRuta = (evento) => {
  let performerName = evento.performances.performer.name;
  performerName = performerName.replace(/ /gi, "-");
  performerName = performerName.replace(/,/gi, "-");
  performerName = performerName.replace(/--/gi, "-");
  performerName = performerName.toLowerCase();

  let venueLocation = evento.venue.location;
  venueLocation = venueLocation.replace(/ /gi, "-");
  venueLocation = venueLocation.replace(/,/gi, "-");
  venueLocation = venueLocation.replace(/--/gi, "-");
  venueLocation = venueLocation.toLowerCase();

  let venueName = evento.venue.name;
  venueName = venueName.replace(/ /gi, "-");
  venueName = venueName.replace(/,/gi, "-");
  venueName = venueName.replace(/--/gi, "-");
  venueName = venueName.toLowerCase();

  let hour = evento.hour;
  hour = hour.replace(/ /gi, "-");
  hour = hour.replace(/:/gi, "-");
  let eventFecha = `${evento.year}-${evento.month2}-${evento.day2
    }-${hour}`;

  let ruta = `/book/${performerName}/${venueLocation}-${venueName}-${eventFecha}/${evento.id
    }`;

  return ruta

}



export default {
  getEventsStructuredData,
  getRuta,
  breadcrumbList,
  organization,
  cityList,
  search
}
