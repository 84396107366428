
/* eslint-disable */
export default {
  data() {
    return {};
  },
  props: {
    toast: {
      type: String
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    clickGetCode() {
      this.$emit("clickGetCode");
    }
  }
};
