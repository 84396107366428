export default {
 
  methods: {
 
    onVisible(element, callback) {
      new IntersectionObserver((entries, observer) => {
        let visible = false;
        let percentage = 0 
        entries.forEach((entry) => {
          percentage = entry.intersectionRatio
          if (entry.intersectionRatio > 0) {
            visible = true;
          }
        });

        callback({
          visible,
          percentage,
          element,
          observer,
        });

        observer.disconnect();
      }).observe(element);
    },
    positionToast(parentContainer, refToast) {
      if (!parentContainer || !refToast) return;

      if (!window.screenTop && !window.screenY) {
      } else {
      }

      let maxHeight = Math.max(
        window.innerHeight || 0,
        window.outerHeight || 0
      );
      let minHeight = Math.min(
        window.innerHeight || 0,
        window.outerHeight || 0
      );

      let D = document;
      let margin_bottom = 0;
      let scrollHeight = D.documentElement.scrollHeight;

      scrollHeight = Math.max(
        Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
        Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
        Math.max(D.body.clientHeight, D.documentElement.clientHeight)
      );

      let scrollTop = window.pageYOffset; //document.documentElement.scrollTop;
      scrollTop =
        document.body.scrollTop ||
        document.documentElement.scrollTop ||
        window.pageYOffset;

      let distanciaFinScrollBotton = scrollHeight - scrollTop - minHeight;

      parentContainer.style.minHeight = minHeight; ///

      console.log(distanciaFinScrollBotton);
      if (distanciaFinScrollBotton <= 91) {
        if (window.innerWidth <= 768) {
          margin_bottom = Math.abs(scrollHeight - scrollTop - minHeight - 89);
        } else {
          if (distanciaFinScrollBotton <= 64) {
            margin_bottom = Math.abs(scrollHeight - scrollTop - minHeight - 63);
            if (window.innerHeight == screen.height) {
              margin_bottom = Math.abs(64);

              //margin_bottom = Math.abs(scrollHeight - scrollTop - minHeight - 63);
            } else {
            }
          } else {
            margin_bottom = 0;
          }
        }
      } else {
        margin_bottom = 0;
      }

      refToast.style.marginBottom = margin_bottom + "px";
    },

    windowResize: function () {
      this.positionToast();
    },
  },
};
