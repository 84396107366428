import events from '@/api/events';
import api from '@/api/api';
import moment from 'moment';
import pushIfNew from '@/util/push_if_new';


let vegas = {
  lat: 36.17497, //las vegas
  lon: -115.1372, //las vegas
};

var getEvents = async (lat, lon, page= 1, per_page= 100) => {
 
    let tevo5NextDaysCarrusel = {
      eventos: [],
      locality: '',
      query: {}
    };

    /*lat = vegas.lat
    lon = vegas.lon*/

    let currentDate = moment();
    let occurs_at_gte = moment(currentDate, moment.ISO_8601).format();
    occurs_at_gte = occurs_at_gte.substring(0, occurs_at_gte.length - 6);
    let occurs_at_lte = currentDate.add(15, 'd');
    occurs_at_lte = moment(occurs_at_lte, moment.ISO_8601).format();
    occurs_at_lte = occurs_at_lte.substring(0, occurs_at_lte.length - 6);
 
    let query = {

      category_id: 54,
      category_tree: true,
      'occurs_at.gte': occurs_at_gte,
      only_with_tickets: 'all',
      order_by: 'events.popularity_score DESC',
      page,
      per_page,
      within: 25,
      min_and_max_price: true
    };

    let query2 = {
      category_id: 54,
      category_tree: true,
      'occurs_at.gte': occurs_at_gte,
      only_with_tickets: 'all',
      order_by: 'events.popularity_score DESC',
      page,
      per_page,
      min_and_max_price: true
    };


    if(lat  && lon   ){
      if(lat !=0 && lon !=0){
        query2.lat = lat
        query2.lon  = lon 
        query2.within =  25
      }
    
    }

    let eventos1 = []
    let eventos2 = []

    
    let locality = 'North America';
 

    if( query2.lat ){
      try {
        [eventos1, eventos2] = await Promise.all([
        events.actionGetEventTevoQuery( query ), 
        events.actionGetEventTevoQuery( query2 )])
      } catch (error) {
        
      }
    }else{
      try {
        eventos1 = await events.actionGetEventTevoQuery( query ) 
      } catch (error) {
        
      }
    }

 

    if(eventos2.length> 0 && lat !=0 && lon !=0 ){
      if (eventos2[0] != undefined) {
        if (eventos2[0].venue != undefined) {
          if (eventos2[0].venue.location != undefined) {
            locality = eventos2[0].venue.location;
          }
        }
      }
    }

 
      
    tevo5NextDaysCarrusel.locality = locality;  

  

  
    
      
    
    //tevo5NextDaysCarrusel.eventos = data
    if (eventos2.length > 0) {
      for (let i = 0; i < eventos2.length; i++) {
        eventos2[i].isFavorite = false;
        pushIfNew.pushIfNew(tevo5NextDaysCarrusel.eventos, eventos2[i]);      
      }

    } 

    if(eventos2.length <= 4){


      tevo5NextDaysCarrusel.query = query

      for (let i = 0; i < eventos1.length; i++) {
        eventos1[i].isFavorite = false;
        pushIfNew.pushIfNew(tevo5NextDaysCarrusel.eventos, eventos1[i]);      
      }
    }else{
      tevo5NextDaysCarrusel.query  = query2
    }






   
    return tevo5NextDaysCarrusel
   
 
};

 

var getMorePopularPerformers = async () => {
 
    let parametros = {
      category_id: 54,
      category_tree: true,

      only_with_upcoming_events: true,
      order_by: 'performers.popularity_score DESC',
      page: 1,
      per_page: 24,
    };

    let performers  = []
    try {
      let res = await  api.searchPerformerByQuery(parametros);
      performers = res.data.performers;
    } catch (error) {
      performers = []
    }
    

    for (let i = 0; i < performers.length; i++) {
      performers[i].venue = {
        location: '',
        name: '',
      };

      performers[i].activado = false;
      performers[i].isFavorite = false;
      performers[i].occurs_at_format = '';
    }

    return performers
 
    
  
};

export default {
  getEvents,
  getMorePopularPerformers,
};
