

import categoriasMenu from "@/api/categorias/categorias";
 
export default {
  computed: {
   
  },
  data() {
    return {
      concertsMenu: categoriasMenu.concerts,
      sportsMenu: categoriasMenu.sports,
      specialEventsMenu: categoriasMenu.special_events,
      theatreMenu: categoriasMenu.theatre,
    }
  },
  async mounted() {
    this.$nextTick(() => {})

 

  },
  watch: {
 

  }, 
  methods: {
 
  },


}
