export default function vScrollPosition( vScrollDistance) {
    return {
      data() {
        return {
          [vScrollDistance] : 0
        }
      },
      created() {
        // Only execute this code on the client side, server sticks to [0, 0]
        if (!this.$isServer) {
          this._scrollListener = () => {
            let minHeight = Math.min(
              window.innerHeight || 0,
              window.outerHeight || 0
            );
      
            let D = document;
            let scrollHeight = D.documentElement.scrollHeight;
      
            scrollHeight = Math.max(
              Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
              Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
              Math.max(D.body.clientHeight, D.documentElement.clientHeight)
            );
      
            let scrollTop = window.pageYOffset; //document.documentElement.scrollTop;
            scrollTop =
              document.body.scrollTop ||
              document.documentElement.scrollTop ||
              window.pageYOffset;
 
             /*console.log( `scrollHeight ${scrollHeight}`)
            console.log( `scrollTop ${scrollTop}`)
            console.log( `minHeight ${minHeight}`) */
            this.vScrollDistance = scrollHeight - scrollTop -  minHeight ;
            //console.log(`this.vScrollDistance ++++++++ ${this.vScrollDistance}`)

           
          }
  
          // Call listener once to detect initial position
          this._scrollListener()
  
          // When scrolling, update the position
          window.addEventListener('scroll', this._scrollListener)
          document.addEventListener("resize", this._scrollListener);
 
          document.addEventListener("touchmove", this._scrollListener, false);
        }
      },
      beforeDestroy() {
        // Detach the listener when the component is gone
        window.removeEventListener('scroll', this._scrollListener)
      }
    }
  }
  