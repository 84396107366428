
/* eslint-disable */
import PositionToast from "@/util/position_toast";
import vScrollPosition from "@/mixins/v_scroll_position";
import { mapMutations, mapGetters } from "vuex";
import {apStyleTitleCase} from 'ap-style-title-case'
export default {
  extends: PositionToast,
  mixins: [vScrollPosition("vScrollDistance")],
  computed: {
    ...mapGetters({
      media_match: "media_match",
      
    }),
    messageTitle: function(){
      return apStyleTitleCase(this.message)
    }
  },
  data() {
    return {
      isMounted:false,
      marginLeft: "auto",
      marginBottom: "0px",
      maxWidth: "726px",
      footerHeight: 0,
      activateTranstion:null,
      footerVisible: false,
    };
  },
  props: {
    mode: {
      type:String,
      default:'none', //'seat'
    },
    message: {
      type: String,
      default: "Hey!!! Get a 10% promo code",
    },
    buttonText: {
      type: String,
      default: "GET CODE",
    },
  },
  watch: {
    footerVisible: function(newVal){
 //this.observerFooter()
   
    },
    vScrollDistance: function (newVal) {
      if(this.$route.path === '/nearme') return //TODO remove comment when lauch nearme project
      this.recalculateBottomPosition(newVal)
      
    },
  },

  mounted() {
    this.$nextTick(async function () {
      this.activateTranstion = {}

      this.isMounted = true
      this.watchWindowSize()
   
   
      //this.observerFooter()

      /*const scrollContent = document.querySelector('.container_seat_right'); 
      console.log(`container_seat_right *************** ${scrollContent}`)
      console.log(scrollContent)
      scrollContent?.addEventListener('scroll', () => {
        const scrolled = scrollContent.scrollTop; // reuse `scrollContent` innstead of querying the DOM again
        console.log(scrolled);
      });

   */

      const scrollContent2 = document.querySelector('.results_tickets'); 
 
      scrollContent2?.addEventListener('scroll', () => {
        if(this.bodyWidth > 768)
        this.observerFooter()
      }); 

//second_section
      
    });
  },

  methods: {
    observerFooter(){

      this.onResize()

   


      let div  = "#page-footer"
      if(this.bodyWidth <= 768){
        console.log("#footer-mobile")
          div  = ".footer_general" //"#footer-mobile"
          
      }

      this.onVisible(
        document.querySelector(div),
        ({ visible, percentage, element, observer }) => {

          console.log(`${this.footerHeight } ... ${percentage}`)
          this.footerVisible = visible
          
          this.marginBottom = `${Math.floor(this.footerHeight * percentage) }px;`;

          if(this.bodyWidth <= 768 && this.isMounted  === true ){
           
            this.isMounted = false

            if( window.innerHeight == screen.height  &&  this.footerHeight === 89  ) {
               //this.marginBottom  = `89px;`;
               
            } 

          } 

        }
      )
    },


    recalculateBottomPosition(value){
      //console.log(value)
      let intMarginBottom = Math.abs(value - this.footerHeight);
      
      if (value <= this.footerHeight) {
        this.marginBottom = `${intMarginBottom}px;`;
        if( window.innerHeight == screen.height  &&  this.footerHeight === 89  ) {
          
           //this.marginBottom  = `89px;`;
 
        } 
      } else {
        this.marginBottom = `0px;`;
      }

    
 

       
      

    },

    watchWindowSize(){

        const body = document.getElementsByTagName("body")[0];
 
  
        // Initialize resize observer object
        let resizeObserver = new ResizeObserver(() => {
               
            // Set the current height and width
            // to the element
          // window.innerHeight;
           //window.innerWidth;
      
          this.bodyWidth = window.innerWidth


          this.observerFooter()
           //console.log(window.innerHeight)
           
        });
           
        // Add a listener to body
        resizeObserver.observe(body);

    },
    clickGetCode() {
      this.$emit("clickGetCode");
    },
    clickGetCodePromoRegister() {
      this.$emit("clickGetCodePromoRegister");
    },
    clickSkip() {
      this.$emit("clickSkip");
    },

    onResize(){

      if(this.media_match <=1){
        this.footerHeight = 89

      }else{
        this.footerHeight = 63 
        if(this.mode == 'seat'){
          this.footerHeight = 89
        }
      }


      if(this.media_match >= 2  ){
        if(this.media_match ==2 ){
          this.maxWidth = `584px`
        }else{
          this.maxWidth = `726px`
        }
        let content_tickets = document.getElementById('content_tickets')
        if(content_tickets && this.mode == `seat`){
          this.maxWidth = `${content_tickets?.offsetWidth - 20 }px`;
          
          this.marginLeft = `10px`
        }
      }else{
        this.maxWidth = `100%`
        this.marginLeft = `auto`
      }
    },
 

  },

  destroyed: function() {
 
  }


};
